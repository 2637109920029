import React from "react"
import { YMaps, Map, Placemark } from "react-yandex-maps"

const Ymaps = ({ height }) => (
  <YMaps>
    <div className="mb-1">
      <Map
        width="100%"
        height={height}
        defaultState={{
          center: [40.757282, 72.343439],
          zoom: 14,
        }}
      >
        <Placemark geometry={[40.757282, 72.343439]} />
      </Map>
    </div>
  </YMaps>
)

export default Ymaps
