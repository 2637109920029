import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Ymaps from "../components/Ymaps"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"

const ContactPage = ({ intl, location }) => {
  return (
    <>
      <SEO title="Fanomed clinics" description="Fanomed clinics" />
      <div>
        <NavBar location={location} />
      </div>
      <Section white>
        <Columns>
          <Column is8>
            <div className="is-shadow">
              <Ymaps height="70vh" />
            </div>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3 className="is-size-6-mobile">
                    {intl.formatMessage({
                      id: `footer.col_1.title`,
                    })}
                  </h3>
                  <p>
                    {" "}
                    {intl.formatMessage({
                      id: `footer.col_1.location`,
                    })}
                  </p>
                  <h3 className="is-size-6-mobile">
                    {intl.formatMessage({
                      id: `footer.col_1.title-2`,
                    })}
                  </h3>
                  <div className="mb-1">
                    <a
                      href="tel:+998742278297"
                      className="button is-uppercase is-primary has-text-weight-bold is-small has-family-pt-sans mr-05"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.phone`,
                      })}
                    </a>
                    <a
                      href="tel:+998934285558"
                      className="button is-uppercase is-primary has-text-weight-bold is-small has-family-pt-sans mr-05"
                    >
                      {intl.formatMessage({
                        id: `footer.col_1.mobile-phone`,
                      })}
                    </a>
                  </div>
                  <h3 className="is-size-6-mobile">
                    {intl.formatMessage({
                      id: `footer.col_1.title-3`,
                    })}
                  </h3>
                  <p>
                    {" "}
                    {intl.formatMessage({
                      id: `footer.col_1.mail`,
                    })}
                  </p>
                </div>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
    </>
  )
}

export default injectIntl(ContactPage)
